import { createUseStyles } from 'react-jss';
import { BaseTheme, colorPalette } from '../Config/Theme';
import { videos } from '../Content/Videos';

export const Main = () => {
    const style = useStyles();

    return (
        <div className={style.container}>
            <div className={style.contentContainer}>
                <div className={style.content}>
                    <div className={style.videoContainer}>
                        <video
                            src={videos.intro}
                            autoPlay={true}
                            loop={true}
                            controls={false}
                            className={style.video}
                            muted={true}
                        />
                    </div>
                    <div className={style.text}>Coming soon...</div>
                </div>
            </div>
        </div>
    );
};

const useStyles = createUseStyles((theme: BaseTheme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'hidden',
    },
    contentContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    content: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    image: {
        marginTop: '4rem',
        maxWidth: '75%',
    },
    text: {
        color: colorPalette.white,
        fontSize: '3rem',
        fontWeight: 'bold',
        paddingTop: '2rem',
    },
    videoContainer: {
        display: 'flex',
        justifyContent: 'center',
        background: colorPalette.pureBlack,
    },
    video: {
        width: '100%',
        aspectRatio: '16/9',
    },
}));
