import '@coreui/coreui/dist/css/coreui.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'theming';
import { theme } from './Config/Theme';
import './index.css';
import { router } from './Routes/Router';

const RootBody = () => {
    return (
        <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
        </ThemeProvider>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <RootBody />
    </React.StrictMode>,
);
