export const colorPalette = {
    pureBlack: '#000000',
    white: '#FFFFFF',
    gold: '#D7B14A',
    red: '#FF0000',
    gray: '#6f6f6f',
    blue: '#45e1ff',
    purple: '#9505ca',
    brown: '#8b4513',
};

export const theme: BaseTheme = {
    background: {
        static: colorPalette.white,
    },
    text: {
        primaryColor: colorPalette.white,
        secondaryColor: colorPalette.gold,
    },
    size: {
        maxWidth: '80rem',
        minWidth: '40rem',
    },
};

export interface BaseTheme {
    background: {
        static: string;
    };
    text: {
        primaryColor: string;
        secondaryColor: string;
    };
    size: {
        maxWidth: string;
        minWidth: string;
    };
}
